<template>
  <sub-page
      :title="$t('AutomatedLetters')"
      v-model="$store.state.newsletter.emailPageData"
      header-route="newsletter"
  >
    <ws-data-table
        :items="itemsFiltered"
        :headers="headers"
        class="mt-5"
        :row-action="openDesign"
    >
      <template #item.name="{item}">
        <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
      </template>
      <template #item.newsletter_design_id="{item}">
          <h5 v-if="!item.newsletter_design_id"> {{ $t('Default') }} </h5>
          <h5 v-else>{{  $t('Custom')  }}</h5>
      </template>

    </ws-data-table>

    <template #dialog>
      <!-- Design Editor-->
      <v-dialog
          v-model="editDesign"
          fullscreen>
        <email-design
            @updateId="updateDesignData"
            v-if="editDesign"
            @close="editDesign = false"
            :system-email="selectedSystemMail.type"
            :uuid="selectedSystemMail.newsletter_design_id"
        />
      </v-dialog>

      <!-- Overlay-->
      <v-fade-transition>
        <v-sheet v-if="editDesign" style="position:fixed; top : 0; bottom: 0;left: 0;right: 0" ></v-sheet>
      </v-fade-transition>
    </template>
  </sub-page>

</template>

<script>
import emailDesign from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/emailDesign";
export default {
  name: "newslettersTable",
  components : {
    emailDesign
  },
  data() {
    return {
      editDesign : false,
      selectedSystemMail : {},
      navigation : 'system',
      systemItems : [
        { name : this.$t('newsletter.auto.password_change')     , type : 'password_change_confirmation'        , newsletter_design_id : null },
        { name : this.$t('newsletter.auto.new_registration')    , type : 'user_registration_welcome'       , newsletter_design_id : null },
        { name : this.$t('newsletter.auto.course_registration') , type : 'course_registration_welcome', newsletter_design_id : null },
        { name : this.$t('newsletter.auto.course_new_order')    , type : 'course_new_order', newsletter_design_id : null },
      ]
    }
  },
  computed : {
    headers() {
      return [
          { text : this.$t("Name")   , value : "name" },
          { text : this.$t("Design") , value : "newsletter_design_id" },
      ]
    },
    itemsFiltered() {
      let items = []
      if ( this.navigation === 'system') {
        return this.systemItems
      }
      return items
    }
  },
  methods : {
    updateDesignData(uuid) {
      this.selectedSystemMail.newsletter_design_id = uuid
    },
    openDesign(item) {
      this.selectedSystemMail = item
      this.editDesign = true
    }
  }
}
</script>

<style scoped>

</style>